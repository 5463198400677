import React from "react"
import { Link } from "gatsby"
import SEO from '../components/seo'
import Layout from "../components/layout"

export default ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <Layout>
      <SEO title="Parsec Research" />
      <div>
        {edges.map(edge => (
          <PostStub key={edge.node.id} post={edge.node} />
        ))}
      </div>
    </Layout>
  )
}

function PostStub ({ post }) {
  return (
    <div className="post-stub">
      <span className="title">
        <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
      </span>
      <span className="published-at">{post.frontmatter.date}</span>
      <div>
        {post.excerpt}
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 400)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`
